import React from 'react';
import { GoArrowUp } from 'react-icons/go';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';

function ActionDropdown(props) {
  const { actionList = [], id, name } = props;
  return (
    <Menu>
      <MenuButton display="grid" placeContent="center" h="4.4rem" w="2.4rem">
        <GoArrowUp />
      </MenuButton>
      <MenuList>
        {actionList.map((action) => (
          <MenuItem
            key={action.name}
            onClick={() => action.method(id)}
            data-testid={(action.name + ' ' + name)?.replace(/\s/g, '-').toLowerCase()}>
            {action.display}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

export { ActionDropdown };
