import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useMutation } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useToastify } from 'hooks/useToastify';
import { SEND_MESSAGE } from 'query';

import { BaseInput, CustomButton } from 'components/shared';

import { MessageCardComponent } from './MessageCard';

export const SingleWishes = ({ wish, employee, closeModal }) => {
  const [message, setMessage] = useState(
    process.env.NODE_ENV === 'test' ? 'Happy Birthday Samuel' : ''
  );

  const { successToast, errorToast } = useToastify();

  const [sendMessage, { loading }] = useMutation(SEND_MESSAGE);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await sendMessage({
        variables: {
          data: {
            message: message,
            userId: employee?._id,
          },
        },
      });
      res?.data?.sendBirthdayWish?.status === 200
        ? successToast(res?.data?.sendBirthdayWish?.message)
        : errorToast('Error occures');
    } catch (error) {
      return error;
    } finally {
      closeModal();
    }
  };
  return (
    <>
      <Header>
        <p>Send Wishes</p>
        <IoClose cursor={'pointer'} size="22" onClick={() => closeModal()} />
      </Header>
      <MessageCardComponent wish={wish} data-testid="single-message-component">
        <p className={'label'}>Message</p>
        <BaseInput
          onChange={({ target }) => setMessage(target.value)}
          type="textarea"
          data-testid="message-input"
        />
        <Flex justifyContent={'flex-end'} mt="3.2rem">
          <CustomButton
            data-testid="send-wish"
            name="Send"
            disabled={!message}
            onClick={handleSubmit}
            loading={loading}
            type="submit"
            variant="primary"
            h="48px"
            mW="125px">
            Send
          </CustomButton>
        </Flex>
      </MessageCardComponent>
    </>
  );
};

const Header = styled(Flex)`
  justify-content: space-between;
  padding: 3.4rem 2.4rem 0;

  p {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.117188px;
    color: #23273b;
  }
`;
