import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import cx from 'classnames';

import Logo from '../../assets/icons/Logo.svg';
import { useMenuContext } from '../../context';
import {
  EmployeeEmployment,
  EmployeePersonal,
  LineManager,
  SidebarEmploymentData,
  SidebarMainData,
  SidebarOrganizationData,
  SidebarRecruitmentData,
} from '../../data/SidebarData';

import SidebarItem from './SidebarItem';
import { DropDownIcon } from './Svgs';

const Sidebar = ({ isMenuOpen, setIsMenuOpen, isEmployee, isLineManager }) => {
  const pathname = useLocation();
  const { isOpen } = useMenuContext();

  useEffect(() => {
    handleSetMobileMenu();
  }, [pathname]);
  const handleSetMobileMenu = () => {
    if (window.screen.width < 768) {
      setIsMenuOpen(() => false);
    }
  };

  function toggleOpen(e) {
    let label = e.target.closest('.group-label');
    label.classList.toggle('open');
  }

  return (
    <Box data-testid="menu-data" height="100%">
      {isMenuOpen && (
        <SidebarWrapper className={cx('scroll-bar', { isOpen })}>
          <Box py="3rem" mb="2.4rem" className="sidebar__header">
            <img src={Logo} alt="logo" />
          </Box>
          {isEmployee ? (
            <>
              <Header heading="PERSONAL" toggleOpen={toggleOpen} />
              {myFunc(EmployeePersonal)}

              <Header heading="EMPLOYMENT" toggleOpen={toggleOpen} />
              {myFunc(EmployeeEmployment)}

              <>
                {isLineManager ? (
                  <>
                    <Header heading="LINE MANAGER" toggleOpen={toggleOpen} />
                    {myFunc(LineManager)}
                  </>
                ) : null}
              </>
            </>
          ) : (
            <>
              {myFunc(SidebarMainData)}

              <Header heading="RECRUITMENT" toggleOpen={toggleOpen} />
              {myFunc(SidebarRecruitmentData)}

              <Header heading="EMPLOYEE" toggleOpen={toggleOpen} />
              {myFunc(SidebarEmploymentData)}

              <Header heading="ORGANIZATION" toggleOpen={toggleOpen} />
              {myFunc(SidebarOrganizationData)}
            </>
          )}
        </SidebarWrapper>
      )}
    </Box>
  );
};

const myFunc = (sidebarItemData) => {
  return (
    <Box className="nav-group__list">
      {sidebarItemData.map((item, id) => {
        return (
          <Flex key={id}>
            <SidebarItem {...item} />
          </Flex>
        );
      })}
    </Box>
  );
};

const Header = ({ heading, toggleOpen }) => {
  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      className={cx('group-label')}
      onClick={toggleOpen}>
      <Text style={headingStyle}>{heading}</Text>
      <Box className="icon">{DropDownIcon}</Box>
    </Flex>
  );
};

const headingStyle = {
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '19px',
  color: '#BCBFCA',
  marginBottom: '1rem',
  marginTop: '2rem',
};

const SidebarWrapper = styled(Box)`
  //position: fixed;
  height: 100%;
  width: 100%;
  max-width: 25rem;
  background-color: #23273b;
  color: #fff;
  padding: 0 2.4rem 2.4rem;
  overflow-y: scroll;
  position: fixed;
  z-index: 5000;
  left: -100%;
  transition: left 300ms ease;

  .sidebar__header {
    position: sticky;
    top: 0;
    background-color: inherit;
  }

  .sidebar__item {
    padding: 1.2rem 1.2rem;
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    border-radius: 0.4rem;
  }

  &.isOpen {
    left: 0;
  }

  @media (min-width: 768px) {
    position: relative;
    left: 0;
    z-index: 100;
  }

  .group-label {
    user-select: none;
    cursor: pointer;

    .icon {
      transform-origin: center;
      transform: rotate(-90deg);
      transition: transform 250ms ease;
    }

    &.open .icon {
      transform: rotate(0);
    }
  }

  .group-label + .nav-group__list {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 300ms ease;
  }

  .group-label.open + .nav-group__list {
    max-height: 100%;
  }
`;

export { Sidebar };
