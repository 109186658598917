import React from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { Box, Flex, FormControl, Image, Text } from '@chakra-ui/react';
import { FETCH_ALL_ADMINS } from 'query';
import { inputError } from 'utils';

import {
  BaseDropdown,
  BaseInput,
  CustomButton,
  MultiSelectCreatableDropdown,
  SimpleModal,
} from 'components';

import calendar from '../image/Calendar.svg';
import cross from '../image/cross.svg';
import Timer from '../image/Timer.svg';

export const ScheduleInterview = ({
  openModal,
  setOpenModal,
  handleSubmit,
  register,
  control,
  errors,
  state,
  id,
  loading,
  toggleModal,
}) => {
  const { data: fetchAllAdmins } = useQuery(FETCH_ALL_ADMINS, {
    variables: {
      filter: {
        status: 'active',
      },
      page: 1,
    },
  });
  const fetchedAllAdmins = fetchAllAdmins?.fetchAllAdmins?.data;

  const admins = fetchedAllAdmins?.map(({ _id, firstName, lastName }) => {
    const fullNamesOfAdmins = `${firstName} ${lastName}`;
    return { label: fullNamesOfAdmins, value: _id };
  });

  return (
    <SimpleModal
      showModal={openModal}
      setShowModal={toggleModal}
      setOpenModal={setOpenModal}
      id={id}
      width={'453px'}>
      <Box background={'#FAFCFF'} p={'27px 45px 15px 32px'}>
        <Flex justifyContent={'space-between'}>
          <Text as="b">Schedule Interview</Text>
          <button>
            <Image src={cross} onClick={() => setOpenModal(false)} data-testid={'cross'} />
          </button>
        </Flex>
        <Text color={'#83889D'} mt={'8px'}>
          Give us a few details about this interview. Once you schedule the interview, the applicant
          and other attendees will be notified.
        </Text>
      </Box>
      <Box p={'24px 45px 48px 32px'}>
        <FormControl
          display={'flex'}
          flexDirection={'column'}
          gap={'24px 0px'}
          onSubmit={handleSubmit}>
          <Box>
            <BaseInput
              placeholder={'e.g. UI/UX Inteview'}
              label={'Interview Name'}
              {...register('interviewName', { required: 'Interview name is required' })}
            />
            {inputError(errors, 'interviewName')}
          </Box>
          <Controller
            defaultValue={''}
            name={'interviewers'}
            control={control}
            render={({ field }) => (
              <MultiSelectCreatableDropdown
                {...field}
                label={'Interviewers'}
                w="100%"
                placeholder={'Add interviewer'}
                options={admins}
                data-testid="selectable-multi"
              />
            )}
          />
          <Box>
            <BaseInput
              type={'date'}
              min={new Date().toISOString().split('T')[0]}
              placeholder={'dd/mm/yyyy'}
              label={'Interview Date'}
              rightIcon={calendar}
              {...register('date', { required: 'Date is required' })}
            />
            {inputError(errors, 'date')}
          </Box>
          <Flex gap={'0px 28px'} justifyContent={'space-between'}>
            <Box>
              <BaseInput
                placeholder={'hh:mm'}
                label={'Start Time'}
                rightIcon={Timer}
                {...register('startTime', { required: 'Required' })}
              />
              {inputError(errors, 'startTime')}
            </Box>
            <Box>
              <BaseInput
                placeholder={'hh:mm'}
                label={'End Time'}
                rightIcon={Timer}
                {...register('endTime', { required: 'Required' })}
              />
              {inputError(errors, 'endTime')}
            </Box>
          </Flex>
          <p>N.B Time should be in 24-hour format</p>
          <Controller
            defaultValue={''}
            name={'type'}
            control={control}
            render={({ field }) => (
              <BaseDropdown
                {...field}
                label={'Interview type'}
                w="100%"
                placeholder={'Interview type'}
                options={interview}
              />
            )}
          />
          {state === 'Physical' && (
            <>
              <BaseInput placeholder={'Address'} label={'Address'} {...register('address')} />

              <Box>
                <BaseInput
                  placeholder={'Link'}
                  label={'Location Link'}
                  {...register('link', { required: 'link is required' })}
                />
                {inputError(errors, 'link')}
              </Box>
            </>
          )}
          {state === 'Online' && (
            <>
              <Box>
                <BaseInput
                  placeholder={'Link'}
                  label={'Location Link'}
                  {...register('link', { required: 'link is required' })}
                />
                {inputError(errors, 'link')}
              </Box>
            </>
          )}
          <BaseInput
            type={'textarea'}
            placeholder={'email seperate with comma'}
            label={'Additional Notes'}
            h={'150px'}
            {...register('additionalNotes')}
          />
          <Flex justifyContent={'flex-end'}>
            <CustomButton
              type="submit"
              onClick={handleSubmit}
              name="Schedule Interview"
              variant={'black'}
              mW={'216px'}
              loading={loading}
            />
          </Flex>
        </FormControl>
      </Box>
    </SimpleModal>
  );
};

const interview = [
  {
    label: 'Physical  Interview',
    value: 'Physical',
  },
  { label: 'Online  Interview', value: 'Online' },
];
